import React from 'react';

import CardCustomer from '../CardCustomer';
import SectionWrapper from '../SectionWrapper';

import './SectionCustomerCard.scss';

interface IProps {
  component: any;
}

/**
 * @return {html}
 */
const SectionCustomerCard = ({ component }: IProps) => {
  let cardGridClasses;
  if (component.variant === 'SixColumn') {
    cardGridClasses = 'grid-section-customer-card-2x1fr-4x1fr-6x1fr';
  } else {
    cardGridClasses = 'grid-section-customer-card-2x1fr-4x1fr';
  }

  return (
    <SectionWrapper
      classes="sw-section-customer-card sw-section-customer-card-pacific-blue"
      collapseBottom={component.collapseBottom}
      collapseTop={component.collapseTop}
      id={component.anchorId || `section-customer-card-${component.id}`}
    >
      <div className={`grid-1fr-1fr2fr grid-gap ${!component.title ? 'untitled' : ''}`.trim()}>
        <div>
          {
            component.title && (
              <>
                <h2 className="h5">{component.title}</h2>
                {
                  component.subTitle && (<div className="h6">{component.subTitle}</div>)
                }
              </>
            )
          }
        </div>

        <div className={cardGridClasses}>
          {
            component.cards.map((customerCard, index) => (<CardCustomer key={index} card={customerCard} />))
          }
        </div>
      </div>
    </SectionWrapper>
  );
};

export default SectionCustomerCard;
