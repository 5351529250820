import React from "react";
import { Link } from "react-router-dom";

import Brand from "../Brand/Brand";
import ContactTeam from "../ContactTeam/ContactTeam";
import SvgIconSyncwork from "../Logos/SvgIconSyncwork";
import SvgLogoTechbar from "../Logos/SvgLogoTechbar";

// store
import { useAppSelector } from "../../state/hooks";
import { selectSiteAttributes } from "../../state/store/slices/mainMenuSlice";

import "./PageFooter.scss";
import DynamicLink from "../DynamicLink/DynamicLink";

/**
 * @return {html}
 */
const PageFooter = ({ personId = null, teamId = null }: any) => {
  const siteAttributes = useAppSelector(selectSiteAttributes);

  if (!siteAttributes) {
    return <></>;
  }

  const theme = siteAttributes.theme?.toLowerCase() || "syncwork";
  const isTechbarTheme = theme === "techbar";

  return (
    <footer id="footer" className={`sw-page-footer sw-page-footer-${theme} ${(teamId || personId) ? "color-bg-alabaster-default" : ""}`.trim()}>
      <ContactTeam personId={personId} teamId={teamId} />
      <div className="polygon">
        <div className="content-container content-container-footer">
          <div className="above-legal">
            <div>
              <div className="logos">
                <div>
                  {isTechbarTheme ? (
                    <Link to="/">
                      <Brand iconComponent={<SvgLogoTechbar color="#fff" height="1.2rem" />} suffix={null} />
                    </Link>
                  ) : (
                    <Link to="/">
                      <Brand iconComponent={<SvgIconSyncwork color="#fff" height="2rem" />} suffix={siteAttributes.subDomainKey !== "www" ? siteAttributes.title : null} />
                    </Link>
                  )}
                </div>
              </div>

              {!isTechbarTheme && (
                <div className="menu">
                  {siteAttributes.menuItems.map((menuItem) => {
                    const pages = menuItem.pages.data;
                    return (
                      <div key={menuItem.id}>
                        {pages.length === 1 && (
                          <DynamicLink key={pages[0].id} to={`/${pages[0].attributes.slug}`}>
                            {pages[0].attributes.title}
                          </DynamicLink>
                        )}

                        {pages.length > 1 && (
                          <>
                            <div>{menuItem.title}</div>
                            <ul className="list-unstyled">
                              {pages.map((page) => (
                                <li key={page.id}>
                                  <DynamicLink to={`/${page.attributes.slug}`}>{page.attributes.title}</DynamicLink>
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            <div className="commendation">
              <div>
                <div className="top-company">
                  <img src="/images/kununu-logo-white-2024.png" alt="" />
                  <div>
                    <span>
                      Wir sind
                      <br />
                      Top-Arbeitgeber
                    </span>
                  </div>
                </div>
                <div className="iso">
                  <img src="/images/ecovadis_2024.png" className="ecovadis" alt="" />
                  <img src="/images/iso-27001.png" alt="" />
                  <img src="/images/iso-9001-2015.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="legal">
            <div>Alle Rechte bei Syncwork AG • © {new Date().getFullYear()}</div>
            <div className="legal-links">
              {siteAttributes.legalNoticePage && <Link to={`/${siteAttributes.legalNoticePage.data.attributes.slug}`}>{siteAttributes.legalNoticePage.data.attributes.menuLabel}</Link>}
              {siteAttributes.dataProtectionPage && <Link to={`/${siteAttributes.dataProtectionPage.data.attributes.slug}`}>{siteAttributes.dataProtectionPage.data.attributes.menuLabel}</Link>}
            </div>
          </div>
          {/* legal */}
        </div>
        {/* content-container content-container-footer */}
      </div>
      {/* polygon */}
    </footer>
  );
};

export default PageFooter;
