import React, { useEffect, useState } from 'react';

import qs from "qs";
import { useTranslation } from 'react-i18next';
import ContactPerson from '../ContactPerson/ContactPerson';
import SectionWrapper from '../SectionWrapper';

import ApiService from "../../services/ApiService";

import './ContactTeam.scss';

/**
 * @return {html}
 */
const ContactTeam = ({ personId, teamId }) => {
  const { t } = useTranslation();

  const [addressAttributes, setAddressAttributes] = useState<any>();
  const [people, setPeople] = useState<any>();
  const [team, setTeam] = useState<any>();

  const setAddressAndTeam = (attributes) => {
    setTeam(attributes);
    setAddressAttributes(attributes?.address?.data?.attributes);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const contactTeamTitleTKey = () => {
    if (people?.length === 1) {
      return (people[0].attributes.gender === 'Female') ? 'contact-team.your-contact-f' : 'contact-team.your-contact-m';
    }

    return 'contact-team.your-contacts';
  };

  useEffect(() => {
    if (teamId) {
      const query = qs.stringify({
        filters: {
          id: {
            $eq: `${teamId}`,
          },
        },
        populate: "deep,5",
      });

      ApiService.get(`/teams?${query}`).then((response) => {
        setPeople(response.data?.data[0]?.attributes.people.data);
        setAddressAndTeam(response.data?.data[0].attributes);
      });
    } else if (personId) {
      const query = qs.stringify({
        filters: {
          id: {
            $eq: `${personId}`,
          },
        },
        populate: "deep,5",
      });

      ApiService.get(`/people?${query}`).then((response) => {
        setPeople([response.data?.data[0]]);
        setAddressAndTeam(response.data?.data[0].attributes);
      });
    } else {
      setPeople(null);
      setAddressAndTeam(null);
    }
  }, [teamId, personId]);

  if (!team) {
    return <></>;
  }

  return (
    <SectionWrapper>
      <div className="sw-team">
        <header>
          <div className="team-heading">{t(contactTeamTitleTKey())}</div>
        </header>

        <div className="persons">
          { people &&
            people?.map(person => (
              <ContactPerson key={person.id} personAttributes={person.attributes} />
            ))
          }
          <div className="contact">
            <div className="font-weight-700">
              {team.name}
            </div>
            {
              team.description && (
                <div>
                  {team.description}
                </div>
              )
            }
            {
              addressAttributes && (
                <div className="mb-1">
                  <div>
                    {addressAttributes.addressLine1}
                  </div>
                  {
                    addressAttributes.addressLine2 && (
                      <div>
                        {addressAttributes.addressLine2}
                      </div>
                    )
                  }
                  <div>
                    {addressAttributes.postcode} {addressAttributes.city}
                  </div>
                </div>
              )
            }
            {
              team.phoneBusiness && (
                <div>
                  <a className="sw-link" href={`tel:${team.phoneBusiness}`} rel="noreferrer nofollow" target="_blank">
                    {team.phoneBusiness}
                  </a>
                </div>
              )
            }
            {
              team.phoneMobile && (
                <div>
                  <a className="sw-link" href={`tel:${team.phoneMobile}`} rel="noreferrer nofollow" target="_blank">
                    {team.phoneMobile}
                  </a>
                </div>
              )
            }
            {
              team.email && (
                <div>
                  <a className="sw-link" href={`mailto:${team.email}`} rel="noreferrer nofollow" target="_blank">
                    {team.email}
                  </a>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default ContactTeam;
