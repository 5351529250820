import React, { useEffect, useRef, useState } from "react";
import qs from "qs";
import { useLocation, useParams } from "react-router-dom";
import { scrollToHash } from "../../global-effects";

// Components
/*
import CookieConsentBox from "../CookieConsentBox";
import PageViewTracker from "../PageViewTracker";
import HeaderHelmet from "../HeaderHelmet";
*/
import Loader from "../Loader";
import PageHeader from "../PageHeader";
import PageContent from "../PageContent";
import PageFooter from "../PageFooter";
import {
  getSubDomainKey, isMainWebsite, isPrerender, personToTeam,
} from "../../utils";

// Services
import ApiService from "../../services/ApiService";

// Store
import { selectSite } from "../../state/store/slices/mainMenuSlice";
import { useAppSelector } from "../../state/hooks";

import "./Page.scss";
// import HeaderHelmet from "../HeaderHelmet";

interface IPageAttributes {
  meta: any;
  header: {
    title: string;
    subTitle: string;
    image: any;
  };
  contactPerson: any;
  team: any;
}

const subDomainKey = getSubDomainKey();

/*
const adjustPageAttributes = (pageAttributes) => {
  const adjustedPageAttributes = { ...pageAttributes };

  if (pageAttributes?.contactPerson?.data) {
    adjustedPageAttributes.team = personToTeam(pageAttributes.contactPerson.data);
    console.log(adjustedPageAttributes);
  }

  return adjustedPageAttributes;
};
*/

const Page = () => {
  // @ts-ignore
  let { slug } = useParams();
  // const { hash } = useLocation();
  // const mainMenu = useAppSelector(selectMainMenu);
  const site = useAppSelector(selectSite);
  const siteAttributes = site?.attributes;
  const scrolledRef = useRef(false);
  const { hash } = useLocation();

  const [personId, setPersonId] = useState<any>(null);
  const [teamId, setTeamId] = useState<any>(null);
  const [pageAttributes, setPageAttributes] = useState<IPageAttributes>({
    meta: {},
    header: {
      title: "",
      subTitle: "",
      image: null,
    },
    contactPerson: null,
    team: null,
  });
  const setPageAttributesAndPossiblyScroll = (pageAttributes2) => {
    setPersonId(pageAttributes2.contactPerson.data?.id);
    setTeamId(pageAttributes2.team.data?.id);
    setPageAttributes(pageAttributes2);
    scrollToHash(scrolledRef, hash);
  };

  const getRedirectUrl = (currentUrl, forceSubDomainKey) => {
    if (subDomainKey === forceSubDomainKey) {
      return currentUrl;
    }

    const colonAndDoubleSlash = "://";
    let redirectUrl = currentUrl.replace(`${colonAndDoubleSlash}${subDomainKey}.`, colonAndDoubleSlash);

    if (!isMainWebsite(forceSubDomainKey)) {
      redirectUrl = redirectUrl.replace(colonAndDoubleSlash, `${colonAndDoubleSlash}${forceSubDomainKey}.`);
    }

    return redirectUrl;
  };

  const getSitesById = async (id) => {
    const query = qs.stringify({
      filters: {
        id: {
          $eq: id,
        },
      },
    });

    return ApiService.get(`/sites?${query}`);
  };

  const getPagesBySlug = (pageSlug) => {
    const query = qs.stringify({
      filters: {
        slug: {
          $eq: `${pageSlug}`,
        },
      },
      populate: "deep,5",
    });

    return ApiService.get(`/pages?${query}`);
  };

  const loadPageData = (pageSlug) => {
    // eslint-disable-next-line consistent-return
    getPagesBySlug(pageSlug).then((response) => {
      const responsePageAttributes = response.data?.data[0]?.attributes;
      if (responsePageAttributes) {
        if (responsePageAttributes.forceSite?.data) {
          getSitesById(responsePageAttributes.forceSite.data.id).then((response2) => {
            const forceSubDomainKey = response2.data?.data[0]?.attributes.subDomainKey;

            if (subDomainKey === forceSubDomainKey || isPrerender() || subDomainKey === "test") {
              setPageAttributesAndPossiblyScroll(responsePageAttributes);
            } else {
              console.log("getRedirectUrl: ", window.location.href, forceSubDomainKey);
              window.location.href = getRedirectUrl(window.location.href, forceSubDomainKey);
            }
          });
        } else {
          setPageAttributesAndPossiblyScroll(responsePageAttributes);
        }
      } else {
        console.log("notfound");
        getPagesBySlug(siteAttributes.notFoundPage.slug).then((notFoundPageResponse) => {
          setPageAttributesAndPossiblyScroll(notFoundPageResponse.data?.data[0]?.attributes);
        });
      }
    });
  };

  useEffect(() => {
    // wait for mainMenu request to complete
    if (!site) {
      return;
    }

    if (!slug) {
      slug = siteAttributes.homePage.data.attributes.slug;
    }

    loadPageData(slug);
  }, [siteAttributes, slug]);

  // show loader as long there is no site menu
  if (!siteAttributes) {
    return (
      <div className="sw-page">
        <Loader />
        <p>v0.1</p>
      </div>
    );
  }

  /* page data exists
  const adjustedPageAttributes = adjustPageAttributes(pageAttributes);
  console.log(adjustedPageAttributes);
  */

  return (
    <>
      {/*
      <CookieConsentBox googleTagManagerId={siteAttributes.googleTagManagerId} />
      <PageViewTracker />
      <HeaderHelmet header={adjustedPageAttributes.header} site={site} meta={adjustedPageAttributes.meta} />
      */}
      <div className="sw-page">
        <PageHeader data={site} pageAttributes={pageAttributes} />
        <PageContent pageAttributes={pageAttributes} />
        <PageFooter personId={personId} teamId={teamId} />
      </div>
    </>
  );
};

export default Page;
